<template>
    <div class="content_wpr">
        <div class="container">
            <div class="section_content w-100">
                <div class="section_header m-0">
                    <h2>{{ title }}<span> {{ ( type == 'openrate' || type == 'clickrate' ) ? count + ' %' : count }}</span> <label v-if="templateLogs.total">{{title == 'Recipient' ? 'Sends' : title == 'Open Rate' ? 'Opens' : title == 'Click Rate' ? 'Clicks' : title}} {{ templateLogs.total }}</label></h2>
                </div>
                <div class="result_wpr new">
                    <div class="actions">
                        <ul>
                            <li class="edit">
                                <label for="check" class="checkbox">
                                    <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                            </li>
                            <li class="optionDrops bulk_action" @click.self="bulkActionDropdown = !bulkActionDropdown" v-if="selectedContacts.length" v-click-outside="closeBulkActionDropdown">
                                Bulk Action<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr megamenu" :class="bulkActionDropdown ? 'active' : ''">
                                    <ul>
                                        <li @click="addTagsToggle = true;">Add Tag</li>
                                        <li @click="removeTagsToggle = true;">Remove Tag</li>
                                        <li @click="sendSequenceToggle = true;">Start Sequence</li>
                                        <li @click="stopSequenceToggle = true;">Stop Sequence</li>
                                        <li @click="sendTemplateToggle = true;">Send Template</li>
                                        <li @click="sendSMSToggle = true;">Send SMS</li>
                                        <li @click="sendEmailToggle = true;">Send Email</li>
                                        <li @click="sendPageToggle = true;">Send Page</li>
                                        <li @click="sendFormToggle = true;">Send Form</li>
                                        <li @click="assignPlaybookToggle = true;">Assign Playbook</li>
                                        <li @click="restrictPlaybookToggle = true;">Restrict Playbook</li>
                                        <li @click="handleDisableEmail()">Disable Email</li>
                                        <li @click="handleDisableSms()">Disable SMS</li>
                                        <li @click="handleDeleteContact()">Bulk Delete</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="search_area">
                                <input type="text" placeholder="Search" :disabled="loader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                <button type="button" class="search_btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="template-log-per-page-filter" />
                            </li>
                            <!-- <li class="list_info">
                                {{ templateLogs.from ? templateLogs.from : 0 }} - {{ templateLogs.to ? templateLogs.to : 0 }} of <span>{{ templateLogs.total ? templateLogs.total : 0 }}</span>
                            </li> -->
                        </ul>
                    </div>
                    <div><line-loader v-if="loader" /></div>
                    <div class="scroll_table">
                        <table class="standard show_header">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Subscriber</th>
                                    <th>Email</th>
                                    <th>Sent at</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="templateLogs.data && templateLogs.data.length">
                                <tr  v-for="(templateLog, t) in templateLogs.data" :key="t">
                                    <td>
                                        <label :for="`template-log-${t}`" class="checkbox" v-if="templateLog.contact">
                                            <input type="checkbox" :id="`template-log-${t}`" :value="templateLog.contact.id" v-model="selectedContacts" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </td>
                                    <td>
                                        <div class="user_wpr"  @click="activeContact = templateLog.contact; profile = true;">
                                            <div class="user_img" v-if="templateLog.contact">
                                                <img :src="templateLog.contact.photo" alt="">
                                            </div>
                                            <h4>
                                                {{ templateLog.contact ? templateLog.contact.name : '' }}
                                                <span v-if="templateLog.contact && templateLog.contact.isDelete" class="text-danger ml-2">[DELETED]</span>
                                            </h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">
                                            <span class="email_add">{{ templateLog.contact ? templateLog.contact.email : templateLog.recipient }}</span>
                                        </div>
                                    </td>
                                    <td>{{ moment(templateLog.date_created).format('DD MMMM, YYYY | hh:mm:ss') }} (UTC)</td>
                                    <td class="action">
                                        <a @click="activeContact = templateLog.contact; launchPad = true;">
                                            <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5" class="text-center">
                                        <div class="empty_box">
                                            <img src="@/assets/images/empty_state.svg">
                                            <h2>No records found</h2>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>
                            {{ templateLogs.from ? templateLogs.from : 0 }} - {{ templateLogs.to ? templateLogs.to : 0 }} of <span>{{ templateLogs.total ? templateLogs.total : 0 }}</span>
                        </li>
                    </ul>
                </div>
                <div class="model_pagination pb-4">
                    <div class="pagination" v-show="templateLogs.total">
                        <pagination v-model="params.page" :pages="templateLogs.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <launch-pad v-model="launchPad" :contact="activeContact" />
    <bulk-action-add-tag v-model="addTagsToggle" :contacts="selectedContacts" />
    <bulk-action-remove-tag v-model="removeTagsToggle" :contacts="selectedContacts" />
    <bulk-action-start-sequence v-model="sendSequenceToggle" :contacts="selectedContacts" />
    <bulk-action-stop-sequence v-model="stopSequenceToggle" :contacts="selectedContacts" />
    <bulk-action-send-template v-model="sendTemplateToggle" :contacts="selectedContacts" />
    <bulk-action-sms v-model="sendSMSToggle" :contacts="selectedContacts" />
    <bulk-action-send-email v-model="sendEmailToggle" :contacts="selectedContacts" />
    <bulk-action-send-page v-model="sendPageToggle" :contacts="selectedContacts" />
    <bulk-action-send-form v-model="sendFormToggle" :contacts="selectedContacts" />
    <bulk-action-send-playbook v-model="assignPlaybookToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-playbook v-model="restrictPlaybookToggle" :contacts="selectedContacts" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const BulkActionAddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const BulkActionRemoveTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemoveTag'))
    const BulkActionStartSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartSequence'))
    const BulkActionStopSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopSequence'))
    const BulkActionSms = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendSms'))
    const BulkActionSendEmail = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendEmail'))
    const BulkActionSendTemplate = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendTemplate'))
    const BulkActionSendPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPage'))
    const BulkActionSendForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendForm'))
    const BulkActionSendPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPlaybook'))
    const BulkActionRestrictPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPlaybook'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Template Logs',

        data () {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    type: '',
                    search: '',
                    id: '',
                },
                selectAll: false,
                bulkActionDropdown: false,
                selectedContacts: [],
                isTyping: false,
                activeContact: {},
                moment,
                addTagsToggle: false,
                removeTagsToggle: false,
                sendSequenceToggle: false,
                stopSequenceToggle: false,
                sendTemplateToggle: false,
                sendSMSToggle: false,
                sendEmailToggle: false,
                sendPageToggle: false,
                sendFormToggle: false,
                assignPlaybookToggle: false,
                restrictPlaybookToggle: false,
                launchPad: false,
            }
        },

        components:{
            LaunchPad,
            BulkActionAddTag,
            BulkActionRemoveTag,
            BulkActionStartSequence,
            BulkActionStopSequence,
            BulkActionSms,
            BulkActionSendEmail,
            BulkActionSendTemplate,
            BulkActionSendPage,
            BulkActionSendForm,
            BulkActionSendPlaybook,
            BulkActionRestrictPlaybook,
        },

        props: {
            template: Object,
            title: String,
            type: String,
            count: Number,
            templateLogWatcher: Number,
        },

        watch: {
            templateLogWatcher (val) {
                const vm  = this;

                vm.params = {
                                id: vm.template.id,
                                page: 1,
                                per_page: 5,
                                type: vm.type,
                                search: '',
                            };

                vm.getTemplateLogs(vm.params);
            },

            'params.per_page' (val) {
                const vm = this;

                vm.params.per_page = val;

                vm.getTemplateLogs(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getTemplateLogs(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getTemplateLogs(vm.params);
                        }
                    }
                }
            },
        },

        emits: ['update:modelValue'],

        computed: {
            ...mapState({
                loader: state => state.templateModule.templateLogLoader,
                templateLogs: state => state.templateModule.templateLogs,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        methods:{
            ...mapActions ({
                getTemplateLogs: 'templateModule/getTemplateLogs',
                bulkDelete: 'contactModule/bulkDelete',
                enableDisableDeliveryStatus: 'contactModule/enableDisableDeliveryStatus',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.bulkActionDropdown = false;

                const filter = vm.$refs['template-log-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['template-log-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getTemplateLogs(vm.params);
            },

            handleDisableEmail () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail sending for the selected contacts.")
                options.preConfirm = function() { return vm.handleDelivaryStatus('email', 0) };

                Swal.fire(options);
            },

            handleDisableSms () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the SMS sending for the selected contacts.")
                options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 0) };

                Swal.fire(options);
            },

            handleDeleteContact () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these contacts', 'Delete')
                          options.preConfirm = function() { return vm.bulkDelete({ contacts: vm.selectedContacts }); };

                Swal.fire(options);
            },

            handleDelivaryStatus (type, status) {
                const vm = this;

                const params = {
                    contacts: vm.selectedContacts,
                    type: type,
                    status: status,
                }

                return vm.enableDisableDeliveryStatus(params).then((result) => {
                    vm.selectAll = false;
                    vm.selectedContacts = [];
                });
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedContacts = vm.filterIndexValue(vm.templateLogs.data);
                } else {
                    vm.selectedContacts = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj['contact'][index]) });

                return records;
            },
        }
    }
</script>

<style scoped>
    .sequence_wpr .content_wpr {
        flex: 1;
        padding: 30px 20px;
    }
    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
    }
    .content_wpr .acton_header .extra_info {
        padding: 15px;
        font-size: 13px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
    }

    .content_wpr .acton_header .extra_info span {
        color: #2f7eed;
        font-weight: 500;
        padding: 0 5px;
        cursor: pointer;
    }

    .section_header h2 {
        display: flex;
        align-items: center;
    }

    .section_header h2 span {
        padding: 3px 8px;
        background: #f2a31d;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 1px 2px rgba(0,0,0,0.15);
        margin: 0 10px 0 10px;
    }
    .section_header h2 label{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 0 10px;
        border-left: 1px solid #cdcdcd;
    }

    /* .share_playbook .result_wpr  {
        width: 100%;
    } */

    .result_wpr .actions > ul {
        width: 100%;
    }

    .result_wpr .actions > ul li.search_area{
        margin-left: auto;
    }
    .result_wpr table{
        min-width: 800px;
    }
    :deep(.pagination){
        max-width: 100%;
    }

    /* .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 10px;
    } */

    @media(max-width: 767px){
        .result_wpr .actions > ul li.search_area input[type=text] {
            width: 200px;
        }
        .dropdown_wpr.megamenu {
            left: 0;
        }
    }
    @media(max-width: 550px){
        .result_wpr .actions > ul li.search_area{
            width: 100%;
            order: 1;
        }
        .result_wpr .actions > ul li.sort_list{
            margin-left: auto;
        }
    }
</style>
